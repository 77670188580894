import React, { useState } from 'react';
import brandingLogo from '../../assets/header.png';
import './header.css';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to an API
    console.log('Form data:', formData);
    closeModal(); // Close the modal after submission
  };

  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">First International <br /> RPA Challenge</h1>

        <div className="header-image-mobile">
          <img src={brandingLogo} alt="Logo" />
        </div>

        <p>This RPA Challenge is different, than others.<br />It’s a serious competition made for junior developers.<br />Show off your knowledge. Join the challenge.</p>
        <button onClick={openModal}>Get Started</button>
      </div>
      <div className="gpt3__header-image">
        <img src={brandingLogo} alt="Logo" />
      </div>
      {isModalOpen && (
        <div className="modal__overlay">
          <div className="modal__content">
            <button className="modal__close-button" onClick={closeModal}>
              Close
            </button>
            <h2>Apply now!</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country:</label>
                <textarea
                  id="country"
                  name="country"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
