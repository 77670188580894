import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title:'We are IT experts.',
    text: 'Our team consists of IT experts from the field of business.',
  },
  {
    title:'We are researchers.',
    text: 'Our team also consists of IT researchers from academic fields.',
  },
  {
    title:'We are nonprofit.',
    text: 'We are working as a nonprofit organization to build our future together.',
  },
  {
    title: 'We are SIIA.',
    text: 'Our organization called SIIA, Scientific IT-Innovations Association.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="about">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Who we are?</h1>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
