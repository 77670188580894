import React from 'react';
import gpt3Logo from '../../logo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
     <h1 className="gradient__text" id="contact"></h1>
    </div>
    <div className="gpt3__footer-contact_details">
    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_div">
        <h4>Contact</h4>
        <a href="mailto:hello@tiie.hu" target="_blank" rel="noreferrer">
        <p>E-mail: hello@tiie.hu</p>
        </a>
      </div>
    </div>

   <div className="gpt3__footer-links_logo">
        <a href="http://www.tiie.hu/" target="_blank" rel="noreferrer">
        <img src={gpt3Logo} alt="gpt3__logo" />
        </a>
      </div>
      </div>
    <div className="gpt3__footer-copyright">
      <p>©2023 Scientific IT-Innovations Association</p>
    </div>
  </div>
);

export default Footer;
