import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatRPA.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="faq">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is RPA Challenge?" text="This challenge is the first RPA development competition all around the world. All applicants have to solve usual business problems, automate processes using software robots.
Just like RPA developers in real life." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond our imagination.</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="What is the timeline?" text="You can apply for the challenge until 31th of October. The second round will start on 30th of November. The final will take place in Budapest, Hungary on 16th of December." />
      <Feature title="How is the competition going?" text="The competition consists of 3 rounds. After your application, you will receive an email with all details for the first round. You will receive your results soon after submission." />
      <Feature title="What are the prizes?" text="The best 3 competitors will be rewarded. 1st place: 250 EUR, 1 year Udemy Membership 2nd place: 150 EUR 3rd place: 100 EUR" />
    </div>
  </div>
);

export default WhatGPT3;